// extracted by mini-css-extract-plugin
export var btn = "WhyChoose-module--btn--ef2f7";
export var cards = "WhyChoose-module--cards--2fb1b";
export var description = "WhyChoose-module--description--ac156";
export var hire = "WhyChoose-module--hire--5d388";
export var hireCyber = "WhyChoose-module--hireCyber--834f0";
export var hireImg = "WhyChoose-module--hireImg--6d600";
export var talent = "WhyChoose-module--talent--f392f";
export var teamBtn = "WhyChoose-module--teamBtn--2db5e";
export var text = "WhyChoose-module--text--343e3";
export var tick = "WhyChoose-module--tick--438fb";