import React from "react"
import * as styles from "./ProcessFlow.module.scss"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import arrow from "../../images/devOpsPage/arrow.svg"
import normalArrow from "../../images/devOpsPage/arrownormal.svg"

const ProcessFlow = ({ strapiData }) => {
  const [hoveredIndex, setHoveredIndex] = React.useState(0)
  return (
    <div className={styles.processFlowDevOps}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></h2>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <Col lg={4} md={6} sm={12} xs={12} key={i} className="gap-30">
                <div
                  className={`${styles.card} ${
                    hoveredIndex === i ? styles.hovered : ""
                  }`}
                  onMouseEnter={() => setHoveredIndex(i)}
                  onMouseLeave={() => setHoveredIndex(0)}
                >
                  <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    decoding="async"
                    loading="lazy"
                    alt={e?.title}
                  />
                  <div className={styles.lineNumberDiv}>
                    <div className={styles.arrowLine}>
                      <img
                        className={styles.hoverArrow}
                        src={arrow}
                        alt="arrow"
                        decoding="async"
                        loading="lazy"
                      />
                      <img
                        className={styles.normalArrow}
                        src={normalArrow}
                        decoding="async"
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <span className={styles.number}> Step 0{i + 1}</span>
                  </div>
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default ProcessFlow
