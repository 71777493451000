// extracted by mini-css-extract-plugin
export var arrowLine = "ProcessFlow-module--arrowLine--09d6b";
export var card = "ProcessFlow-module--card--33f24";
export var cardWrapper = "ProcessFlow-module--cardWrapper--60d11";
export var description = "ProcessFlow-module--description--45204";
export var heading = "ProcessFlow-module--heading--086eb";
export var hoverArrow = "ProcessFlow-module--hoverArrow--57973";
export var hovered = "ProcessFlow-module--hovered--f7dba";
export var lineNumberDiv = "ProcessFlow-module--lineNumberDiv--28375";
export var normalArrow = "ProcessFlow-module--normalArrow--848e8";
export var number = "ProcessFlow-module--number--b34e7";
export var processFlowDevOps = "ProcessFlow-module--processFlowDevOps--92725";