// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--ed9bc";
export var circleTes = "Testimonials2-module--circleTes--6a7b4";
export var content = "Testimonials2-module--content--58871";
export var des = "Testimonials2-module--des--f2f0f";
export var iconContainer = "Testimonials2-module--iconContainer--d69eb";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--b42fb";
export var personBg = "Testimonials2-module--personBg--fd6f5";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--26ffe";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--3bef4";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--475f8";
export var row = "Testimonials2-module--row--9e00f";
export var section = "Testimonials2-module--section--a0966";
export var tesBtn = "Testimonials2-module--tesBtn--c24cd";
export var tesDots = "Testimonials2-module--tesDots--e31d6";
export var tesRec = "Testimonials2-module--tesRec--5acce";
export var testimonials = "Testimonials2-module--testimonials--eabeb";