import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Engagement.module.scss"

const DevOpsEngagement = ({ strapiData, cards = false }) => {
  const data = [
    {
      title: "Staff Augmentation",
      icon: "https://invozone-backend.s3.amazonaws.com/staff_augmentation_2d38971296.svg",
    },
    {
      title: "Dedicated Teams",
      icon: "https://invozone-backend.s3.amazonaws.com/dedicated_teams_b039269a37.svg",
    },
    {
      title: "Product Development",
      icon: "https://invozone-backend.s3.amazonaws.com/fixed_gigs_e698272070.svg",
    },
  ]

  return (
    <div className={styles.customized}>
      <Container className={styles.con}>
        <div className={styles.engagment}>
          <h2 className={styles.mainHeading}>Our Dynamic Engagement Models</h2>
          <p className={`${styles.subHeading}`}>
            Our flexible engagement models are guaranteed to fulfill your
            project needs within your budget.
          </p>
          <div className={styles.flexWrapper}>
            {!cards
              ? data?.map(item => (
                  <div className={`${styles.card}`} key={item?.title}>
                    <div>
                      <img
                        decoding="async"
                        loading="lazy"
                        src={item?.icon}
                        alt={item?.title}
                      />
                    </div>
                    <h3 className={`${styles.pHead} `}>{item?.title}</h3>
                  </div>
                ))
              : strapiData?.cards &&
                strapiData?.cards?.map(item => (
                  <div className={`${styles.card}`} key={item?.title}>
                    <div>
                      <img
                        decoding="async"
                        loading="lazy"
                        src={item?.image1[0]?.localFile?.publicURL}
                        alt={item?.image1[0]?.alternativeText}
                      />
                    </div>
                    <h3 className={`${styles.pHead} `}>{item?.title}</h3>
                  </div>
                ))}
          </div>
          <div className={styles.teamBtn2}>
            <Link to={`/pricing/`} className={styles.btn}>
              {strapiData?.buttons[0]?.title || "Get the Best Quote Today"}
            </Link>
          </div>
        </div>
        <div className={styles.teamBtn}>
          <Link to={`/pricing/`} className={styles.btn}>
            {strapiData?.buttons[0]?.title || "Get the Best Quote Today"}
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default DevOpsEngagement
