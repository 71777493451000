import React from "react"
import * as styles from "./Certification.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
const Certification = ({ strapiData }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 6,
    speed: 500,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }
  return (
    <div className={styles.certification}>
      <React.Fragment>
        <Container>
          <Row className="align-items-center">
            <Col lg={3} md={6} sm={12} xs={12}>
              <h2
                className={styles.heading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
            </Col>
            <Col lg={9} md={6} sm={12} xs={12}>
              <Slider {...settings} className={styles.devOpsRefineImages}>
                {strapiData?.cards &&
                  strapiData?.cards.map((e, i) => (
                    <div key={i}>
                      <img
                        src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                        alt="Certifications"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default Certification
