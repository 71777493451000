// extracted by mini-css-extract-plugin
export var ServicesRefineMian = "ServicesRefine-module--ServicesRefineMian--98951";
export var card = "ServicesRefine-module--card--945c1";
export var cardWrapper = "ServicesRefine-module--cardWrapper--c7ea9";
export var description = "ServicesRefine-module--description--0836b";
export var heading = "ServicesRefine-module--heading--41e58";
export var loader = "ServicesRefine-module--loader--91eaf";
export var lodaMoreBtn = "ServicesRefine-module--lodaMoreBtn--24933";
export var lodalessBtn = "ServicesRefine-module--lodalessBtn--a1e61";
export var showlessdiv = "ServicesRefine-module--showlessdiv--c0ddb";
export var spin = "ServicesRefine-module--spin--87f63";
export var trailBg = "ServicesRefine-module--trailBg--8b532";