import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./tabbing.scss"
import * as styles from "./Technology.module.scss"

const data = [
  {
    title: "Public & Private Clouds",
    tech: [
      {
        name: "AWS",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_51d90f7cc7.svg",
      },
      {
        name: "Azure",
        icon: "https://invozone-backend.s3.amazonaws.com/azure_7686867c9d.svg",
      },
      {
        name: "Google Cloud",
        icon: "https://invozone-backend.s3.amazonaws.com/google_cloud_platform_b522826248.svg",
      },
      {
        name: "Digital Ocean",
        icon: "https://invozone-backend.s3.amazonaws.com/digital_ocean_085e65e983.svg",
      },
      {
        name: "IBM Cloud",
        icon: "https://invozone-backend.s3.amazonaws.com/ibm_cloud_26b4f33bd9.svg",
      },
      {
        name: "Oracle Cloud",
        icon: "https://invozone-backend.s3.amazonaws.com/oracle_cloud_8d2592610b.svg",
      },
    ],
  },
  {
    title: "Infrastructure as a Code",
    tech: [
      {
        name: "Terraform",
        icon: "https://invozone-backend.s3.amazonaws.com/terraform_e7c328b99b.svg",
      },
      {
        name: "AWS Cloudfromation",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_cloudformation_36dab57955.svg",
      },
      {
        name: "Azure Manager",
        icon: "https://invozone-backend.s3.amazonaws.com/azure_resource_manager_5e1d2e5722.svg",
      },
      {
        name: "Pulumi",
        icon: "https://invozone-backend.s3.amazonaws.com/pulumi_e0f563cf26.svg",
      },
      {
        name: "Ansible",
        icon: "https://invozone-backend.s3.amazonaws.com/kubernetes_b96b313f75.svg",
      },
    ],
  },
  {
    title: "Automation and Orchestration",
    tech: [
      {
        name: "Ansible",
        icon: "https://invozone-backend.s3.amazonaws.com/ansible_faa725230e.svg",
      },
      {
        name: "Puppet",
        icon: "https://invozone-backend.s3.amazonaws.com/puppet_c88edb303a.svg",
      },
      {
        name: "Chef",
        icon: "https://invozone-backend.s3.amazonaws.com/chefio_c3e54ab106.svg",
      },
      {
        name: "SaltStack",
        icon: "https://invozone-backend.s3.amazonaws.com/saltstack_ca04e8bc1e.svg",
      },
      {
        name: "Kubernetes",
        icon: "https://invozone-backend.s3.amazonaws.com/kubernetes_b96b313f75.svg",
      },
      {
        name: "Docker Swarm",
        icon: "https://invozone-backend.s3.amazonaws.com/docker_swarm_9d2aac66eb.svg",
      },
    ],
  },
  {
    title: "CI/CD",
    tech: [
      {
        name: "Jenkins",
        icon: "https://invozone-backend.s3.amazonaws.com/jenkins_ae3dc985af.svg",
      },
      {
        name: "Travis CI",
        icon: "https://invozone-backend.s3.amazonaws.com/travis_ci_8c5fd3b920.svg",
      },
      {
        name: "Circle CI",
        icon: "https://invozone-backend.s3.amazonaws.com/circle_ci_31cb946a41.svg",
      },
      {
        name: "GitLab CI/CD",
        icon: "https://invozone-backend.s3.amazonaws.com/gitlab_ci_cd_c982ced527.svg",
      },
      {
        name: "GitHub Actions",
        icon: "https://invozone-backend.s3.amazonaws.com/github_actions_baef7a632c.svg",
      },
      {
        name: "TeamCity",
        icon: "https://invozone-backend.s3.amazonaws.com/teamcity_f663a51ef4.svg",
      },
      {
        name: "Bamboo",
        icon: "https://invozone-backend.s3.amazonaws.com/bamboo_63eec5b320.svg",
      },
      {
        name: "Azure DevOps",
        icon: "https://invozone-backend.s3.amazonaws.com/azure_dev_Ops_48b02feb32.svg",
      },
      {
        name: "GoCD",
        icon: "https://invozone-backend.s3.amazonaws.com/gocd_7449e31598.svg",
      },
      {
        name: "AWS Code Pipeline",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_code_pipeline_310db07d66.svg",
      },
      {
        name: "GitBucket",
        icon: "https://invozone-backend.s3.amazonaws.com/gitbucket_fb9858b4ee.svg",
      },
      {
        name: "ArgoCD",
        icon: "https://invozone-backend.s3.amazonaws.com/argocd_18a97af219.svg",
      },
      {
        name: "Bitbucket",
        icon: "https://invozone-backend.s3.amazonaws.com/bitbucket_2e2c7a58f6.svg",
      },
    ],
  },
  {
    title: "Monitoring & logging",
    tech: [
      {
        name: "Prometheus",
        icon: "https://invozone-backend.s3.amazonaws.com/prometheusio_771789c187.svg",
      },
      {
        name: "Grafana",
        icon: "https://invozone-backend.s3.amazonaws.com/grafana_b08d60e438.svg",
      },
      {
        name: "Elasticssearch",
        icon: "https://invozone-backend.s3.amazonaws.com/elasticsearch_78a2e1f0ee.svg",
      },
      {
        name: "Logstash",
        icon: "https://invozone-backend.s3.amazonaws.com/logstash_9086550275.svg",
      },
      {
        name: "Kibana",
        icon: "https://invozone-backend.s3.amazonaws.com/elasticco_kibana_c5b9414ce9.svg",
      },
      {
        name: "Zabbix",
        icon: "https://invozone-backend.s3.amazonaws.com/zabbix_1e10b95a28.svg",
      },
      {
        name: "Nagios",
        icon: "https://invozone-backend.s3.amazonaws.com/nagios_0f74204188.svg",
      },
      {
        name: "Datadog",
        icon: "https://invozone-backend.s3.amazonaws.com/datadog_cb30aa7fed.svg",
      },
      {
        name: "Splunk",
        icon: "https://invozone-backend.s3.amazonaws.com/splunk_10d2153b51.svg",
      },
      {
        name: "New Relic",
        icon: "https://invozone-backend.s3.amazonaws.com/new_relic_80df5a77d9.svg",
      },
    ],
  },
]

const Establishing = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="tech">
      <div className={styles.globalIndus}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`devTab`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlock">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default Establishing
