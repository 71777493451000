// extracted by mini-css-extract-plugin
export var Head = "Engagement-module--Head--ac3ac";
export var btn = "Engagement-module--btn--e9725";
export var card = "Engagement-module--card--61087";
export var con = "Engagement-module--con--34b6f";
export var customized = "Engagement-module--customized--ce38f";
export var engagment = "Engagement-module--engagment--22d03";
export var flexWrapper = "Engagement-module--flexWrapper--c8f85";
export var mainHeading = "Engagement-module--mainHeading--300e6";
export var pHead = "Engagement-module--pHead--40816";
export var sHead = "Engagement-module--sHead--27009";
export var subHeading = "Engagement-module--subHeading--73cac";
export var teamBtn = "Engagement-module--teamBtn--61fa5";
export var teamBtn2 = "Engagement-module--teamBtn2--42368";