// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--3f407";
export var clientDots2 = "Testimonials-module--clientDots2--960a1";
export var content = "Testimonials-module--content--88889";
export var dots3 = "Testimonials-module--dots3--332a4";
export var hireArrow = "Testimonials-module--hireArrow--93a5f";
export var iconContainer = "Testimonials-module--iconContainer--6b69f";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--d97e4";
export var imgBtn = "Testimonials-module--imgBtn--3ea72";
export var person = "Testimonials-module--person--305d0";
export var personBg = "Testimonials-module--personBg--532ec";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--cd3d1";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--c73be";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--f241b";
export var row = "Testimonials-module--row--e0757";
export var section = "Testimonials-module--section--44630";
export var staffRec = "Testimonials-module--staffRec--0816d";
export var testName = "Testimonials-module--testName--305fa";
export var testimonials = "Testimonials-module--testimonials--5fa5f";
export var testimonialsContent = "Testimonials-module--testimonialsContent--7047e";
export var vector2 = "Testimonials-module--vector2--c43ca";