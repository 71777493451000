// extracted by mini-css-extract-plugin
export var card = "Technology2-module--card--b874c";
export var cardWrapper = "Technology2-module--cardWrapper--4b14b";
export var currentCard = "Technology2-module--currentCard--8f35e";
export var faqsNum = "Technology2-module--faqsNum--59349";
export var faqsSub = "Technology2-module--faqsSub--63f8b";
export var head = "Technology2-module--head--62fb8";
export var iconssCard = "Technology2-module--iconssCard--3fbc9";
export var quesColor = "Technology2-module--quesColor--6009d";
export var quesColorSelected = "Technology2-module--quesColorSelected--08ac5";
export var section = "Technology2-module--section--44b0e";
export var serImg = "Technology2-module--serImg--29741";
export var serial = "Technology2-module--serial--0cae1";
export var serial2 = "Technology2-module--serial2--b6ace";
export var techImg = "Technology2-module--techImg--8a8f4";
export var technologyIcon = "Technology2-module--technologyIcon--17f92";
export var textColor = "Technology2-module--textColor--1da11";
export var yellow = "Technology2-module--yellow--60334";