// extracted by mini-css-extract-plugin
export var cardBg = "Technology-module--cardBg--80d79";
export var cardsContent = "Technology-module--cardsContent--aa3b1";
export var description = "Technology-module--description--65c42";
export var globalIndus = "Technology-module--globalIndus--6274a";
export var heading = "Technology-module--heading--210d1";
export var iconssCard = "Technology-module--iconssCard--70f6f";
export var navItem = "Technology-module--nav-item--2dd20";
export var navbarBlock = "Technology-module--navbarBlock--1104c";
export var tabData = "Technology-module--tabData--eb621";
export var tech = "Technology-module--tech--7e7ab";
export var techIcon = "Technology-module--techIcon--d6de1";
export var techImg = "Technology-module--techImg--834ed";
export var technologyIcon = "Technology-module--technologyIcon--acacf";